/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Nvepgallery.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../resources/logo.svg";
import mail from "../resources/mail.png";
import phone from "../resources/call (1).png";
import logos from "../resources/logo.svg";
import address from "../resources/Location.png";
import photo1 from "../Gallery/IMG-20240131-WA0003.jpg";
import photo2 from "../Gallery/IMG-20240131-WA0004.jpg";
import photo3 from "../Gallery/IMG-20240131-WA0005.jpg";
import photo4 from "../Gallery/IMG-20240131-WA0006.jpg";
// import photo5 from '../Gallery/IMG-20240131-WA0008.jpg'
import photo6 from "../Gallery/IMG-20240131-WA0009.jpg";
import photo7 from "../Gallery/IMG-20240131-WA0013.jpg";
// import photo8 from '../Gallery/IMG-20240131-WA0014.jpg'
import photo9 from "../Gallery/IMG-20240131-WA0015.jpg";
import photo10 from "../Gallery/IMG-20240131-WA0016.jpg";
// import photo11 from '../Gallery/IMG-20240131-WA0017.jpg'
import photo12 from "../Gallery/IMG-20240131-WA0018.jpg";
import photo13 from "../Gallery/IMG-20240131-WA0019.jpg";
// import photo14 from '../Gallery/IMG-20240131-WA0020.jpg'
import photo15 from "../Gallery/IMG-20240131-WA0021.jpg";
import photo16 from "../Gallery/IMG-20240131-WA0022.jpg";
import photo17 from "../Gallery/IMG-20240131-WA0023.jpg";
import photo18 from "../Gallery/IMG-20240131-WA0024.jpg";
import photo19 from "../Gallery/IMG-20240131-WA0025.jpg";
// import photo20 from '../Gallery/IMG-20240131-WA0026.jpg'
import photo21 from "../Gallery/IMG-20240131-WA0027.jpg";
import photo22 from "../Gallery/IMG-20240131-WA0028.jpg";
import photo23 from "../Gallery/IMG-20240131-WA0029.jpg";
import photo24 from "../Gallery/IMG-20240131-WA0030.jpg";
// import photo25 from '../Gallery/IMG-20240131-WA0031.jpg'
import photo26 from "../Gallery/IMG-20240131-WA0032.jpg";
import photo27 from "../Gallery/IMG-20240131-WA0033.jpg";
import photo29 from "../Gallery/IMG-20240131-WA0035.jpg";
// import photo30 from '../Gallery/IMG-20240131-WA0036.jpg'
// import photo31 from '../Gallery/IMG-20240131-WA0037.jpg'
import photo32 from "../Gallery/IMG-20240131-WA0038.jpg";
import photo33 from "../Gallery/IMG-20240131-WA0039.jpg";
import photo34 from "../Gallery/IMG-20240131-WA0040.jpg";
import photo35 from "../Gallery/IMG-20240131-WA0041.jpg";
import photo36 from "../Gallery/IMG-20240131-WA0042.jpg";
import photo37 from "../Gallery/IMG-20240131-WA0043.jpg";
import photo38 from "../Gallery/IMG-20240131-WA0043.jpg";
import photo39 from "../Gallery/IMG-20240131-WA0045.jpg";
// import photo40 from '../Gallery/IMG-20240131-WA0047.jpg'
import photo41 from "../Gallery/IMG-20240131-WA0048.jpg";
// import photo42 from '../Gallery/IMG-20240131-WA0049.jpg'
import photo43 from "../Gallery/IMG-20240131-WA0050.jpg";
import photo44 from "../Gallery/IMG-20240131-WA0051.jpg";
import photo45 from "../Gallery/IMG-20240131-WA0052.jpg";
// import photo46 from '../Gallery/IMG-20240131-WA0053.jpg'
import photo47 from "../Gallery/IMG-20240131-WA0068.jpg";
import photo48 from "../Gallery/IMG-20240131-WA0055.jpg";
import photo49 from "../Gallery/IMG-20240131-WA0056.jpg";
import photo50 from "../Gallery/IMG-20240131-WA0057.jpg";
import photo51 from "../Gallery/IMG-20240131-WA0058.jpg";
import photo52 from "../Gallery/IMG-20240131-WA0067.jpg";
import photo53 from "../Gallery/IMG-20240131-WA0064.jpg";
import photo54 from "../Gallery/IMG-20240131-WA0061.jpg";
import photo55 from "../Gallery/IMG-20240131-WA0063.jpg";
import { Link } from "react-router-dom";

function Nvepgallery() {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    console.log("Scrolling to the top");
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const routes = {
    gallery: "/Gallerysection",
    about: "/",
    contact: "#con",
  };
  return (
    <>
      <div className="container">
        <div className="row mt-5 ">
          {/* <div className='col-3'>
                    <img src={logos} onClick={()=>{(navigate("/"))}} className='logo' style={{height:"3vw"}} alt="" />
                </div>

                <div className='col-9 mb-5'>
                        <ul class="nav justify-content-end fs-5">
                <li class="nav-item">
                    <a class="nav-link active" onClick={()=>{(navigate("/"))}} aria-current="page" href="#cou">Courses</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"  href="#">Gallery</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" onClick={()=>{(navigate("/"))}} href="#abou">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" onClick={()=>{(navigate("/"))}} href="#con">Contact us</a>
                </li>
                </ul>
                </div> */}

          <Navbar
            collapseOnSelect
            expand="lg"
            variant="light"
            className="fs-4 col-sm-12"
          >
            <Navbar.Brand
              className="ms-4 "
              href=""
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                src={logo}
                style={{ height: "6vw", cursor: "pointer" }}
                alt=""
              />
            </Navbar.Brand>
            {/* <Navbar.Brand className='ms-4 d-md-none d-lg-none d-xl-none d-xxl-none' href="#" ><img src={logo} style={{height:"10vw"}} alt="" /></Navbar.Brand> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav ">
              <Nav className="ms-auto  ">
                <NavDropdown
                  href="#cou"
                  className="me-5"
                  title="Courses"
                  onClick={() => {
                    navigate("/");
                  }}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="">Rs-cit</NavDropdown.Item>
                  <NavDropdown.Item href="">Tally</NavDropdown.Item>
                  <NavDropdown.Item href="">Advance Excel</NavDropdown.Item>
                  <NavDropdown.Item href="">English Spoken</NavDropdown.Item>
                  <NavDropdown.Item href="">DCOC</NavDropdown.Item>
                  <NavDropdown.Item href="">DCA</NavDropdown.Item>
                  <NavDropdown.Item href="">ADCTT</NavDropdown.Item>
                  <NavDropdown.Item href="">Computer Basic</NavDropdown.Item>
                  <NavDropdown.Item href="">BCA</NavDropdown.Item>
                  <NavDropdown.Item href="">MCA</NavDropdown.Item>
                  <NavDropdown.Item href="">PGDCA</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#" className="me-5">
                  Gallery
                </Nav.Link>
                <Nav.Link
                  href="#abou"
                  className="me-5"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  href="#con"
                  className="me-5"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Contact Us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>

        <div>
          <div className="row">
            <div className="col-12">
              <h1 className="">Gallery</h1>
            </div>
          </div>

          {/* section 1 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo1}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#imageex"
              />
              <div
                className="modal fade"
                id="imageex"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div>
                        <div className="row text-end">
                          <div className="col text-end">
                            <button
                              type="button"
                              className="btn-close "
                              data-bs-dismiss="modal"
                              aria-label="close"
                            ></button>
                          </div>
                        </div>
                        <img src={photo1} className="w-75 h-50" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo2}
                className="img "
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#imagee"
              />
              <div
                className="modal fade"
                id="imagee"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo2} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo3}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image"
              />
              <div
                className="modal fade"
                id="image"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo3} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section2 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo4}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image1"
              />
              <div
                className="modal fade"
                id="image1"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo4} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-4'>
                    <img src={photo5} className='img' alt="" data-bs-toggle="modal" data-bs-target="#image2" />
                    <div className='modal fade' id='image2' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo5} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
            <div className="col-4">
              <img
                src={photo6}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image3"
              />
              <div
                className="modal fade"
                id="image3"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo6} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4 ">
              <img
                src={photo10}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image7"
              />
              <div
                className="modal fade"
                id="image7"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo10} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section3 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo7}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image4"
              />
              <div
                className="modal fade"
                id="image4"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo7} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 ">
              <img
                src={photo53}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image48"
              />
              <div
                className="modal fade"
                id="image48"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo53} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-4'>
                    <img src={photo8} className='img' alt="" data-bs-toggle="modal" data-bs-target="#image5" />
                    <div className='modal fade' id='image5' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo8} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
            <div className="col-4">
              <img
                src={photo9}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image6"
              />
              <div
                className="modal fade"
                id="image6"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo9} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section4 */}
          <div className="row mt-5">
            {/* <div className='col-4'>
                    <img src={photo11} className='img' alt="" data-bs-toggle="modal" data-bs-target="#image8"/>
                    <div className='modal fade' id='image8' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo11} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
          </div>
          {/* section5 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo13}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image10"
              />
              <div
                className="modal fade"
                id="image10"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo13} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo12}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image9"
              />
              <div
                className="modal fade"
                id="image9"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo12} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-4'>
                    <img src={photo14} className='img' alt=""  data-bs-toggle="modal" data-bs-target="#image11"/>
                    <div className='modal fade' id='image11' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo14} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
            <div className="col-4">
              <img
                src={photo15}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image12"
              />
              <div
                className="modal fade"
                id="image12"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo15} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section6 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo16}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image13"
              />
              <div
                className="modal fade"
                id="image13"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo16} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo17}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image14"
              />
              <div
                className="modal fade"
                id="image14"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo17} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo18}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image15"
              />
              <div
                className="modal fade"
                id="image15"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo18} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section7 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo19}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image16"
              />
              <div
                className="modal fade"
                id="image16"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo19} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-4'>
                    <img src={photo20} className='img' alt=""  data-bs-toggle="modal" data-bs-target="#image17" />
                    <div className='modal fade' id='image17' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body align-items-center'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={photo20} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
            <div className="col-4 ">
              <img
                src={photo29}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image24"
              />
              <div
                className="modal fade"
                id="image24"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo29} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo21}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image18"
              />
              <div
                className="modal fade"
                id="image18"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo21} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section8 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo22}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image19"
              />
              <div
                className="modal fade"
                id="image19"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo22} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo23}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image20"
              />
              <div
                className="modal fade"
                id="image20"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo23} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo24}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image21"
              />
              <div
                className="modal fade"
                id="image21"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo24} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section9 */}
          <div className="row mt-5">
            <div className="col-4">
              <img
                src={photo54}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image49"
              />
              <div
                className="modal fade"
                id="image49"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo54} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-4 '>
                        <img src={photo25} className='img'  alt=""  data-bs-toggle="modal" data-bs-target="#image22" />
                        <div className='modal fade' id='image22' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo25} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
            <div className="col-4">
              <img
                src={photo26}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image55"
              />
              <div
                className="modal fade"
                id="image55"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo26} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo27}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image23"
              />
              <div
                className="modal fade"
                id="image23"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo27} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section10 */}
          <div className="row mt-5">
            {/* <div className='col-4'>
                    <img src={photo30} className='img' alt=""  data-bs-toggle="modal" data-bs-target="#image25" />
                    <div className='modal fade' id='image25' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo30} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
            {/* <div className='col-4'>
                    <img src={photo31} className='img' alt=""  data-bs-toggle="modal" data-bs-target="#image26" />
                    <div className='modal fade' id='image26' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo31} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                 </div> */}
          </div>
          {/* section11 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo32}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image27"
              />
              <div
                className="modal fade"
                id="image27"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo32} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo33}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image28"
              />
              <div
                className="modal fade"
                id="image28"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo33} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo34}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image29"
              />
              <div
                className="modal fade"
                id="image29"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo34} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section12 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo35}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image30"
              />
              <div
                className="modal fade"
                id="image30"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo35} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo36}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image31"
              />
              <div
                className="modal fade"
                id="image31"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo36} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo37}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image32"
              />
              <div
                className="modal fade"
                id="image32"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo37} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* sectio13 */}
          <div className="row mt-5">
            <div className="col-4">
              <img
                src={photo55}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image50"
              />
              <div
                className="modal fade"
                id="image50"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo55} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-4 '>
                        <img src={photo38} className='img'  alt=""  data-bs-toggle="modal" data-bs-target="#image33"/>
                        <div className='modal fade' id='image33' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo38} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
            <div className="col-4">
              <img
                src={photo39}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image35"
              />
              <div
                className="modal fade"
                id="image35"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo39} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 ">
              <img
                src={photo41}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image36"
              />
              <div
                className="modal fade"
                id="image36"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo41} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-4'>
                    <img src={photo40} className='img' alt=""  data-bs-toggle="modal" data-bs-target="#image60" />
                    <div className='modal fade' id='image60' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo40} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                 </div> */}
          </div>
          {/* section14 */}
          <div className="row mt-5">
            {/* <div className='col-4'>
                    <img src={photo42} className='img' alt=""  data-bs-toggle="modal" data-bs-target="#image37" />
                    <div className='modal fade' id='image37' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo42} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}
          </div>
          {/* section15 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo44}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image39"
              />
              <div
                className="modal fade"
                id="image39"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo44} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo45}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image40"
              />
              <div
                className="modal fade"
                id="image40"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo45} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo43}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image38"
              />
              <div
                className="modal fade"
                id="image38"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo43} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-4'>
                    <img src={photo46} className='img' alt=""  data-bs-toggle="modal" data-bs-target="#image41"/>
                    <div className='modal fade' id='image41' tabIndex="-1" aria-hidden="true">
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <div className='modal-body'>
                                        <div className='row text-end'>
                                            <div className='col text-end'>
                                            <button type='button' className='btn-close '
                                            data-bs-dismiss="modal" aria-label='close'></button>
                                            </div>
                                        </div>
                                        <div >
                                            <img src={photo46} className='w-75 h-75' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                 </div> */}
          </div>
          {/* section17 */}
          <div className="row mt-5">
            <div className="col-4 ">
              <img
                src={photo47}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image42"
              />
              <div
                className="modal fade"
                id="image42"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo47} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo48}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image2"
              />
              <div
                className="modal fade"
                id="image42"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo48} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo49}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image44"
              />
              <div
                className="modal fade"
                id="image44"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo49} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section19 */}
          <div className="row mt-5 mb-5">
            <div className="col-4 ">
              <img
                src={photo50}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image45"
              />
              <div
                className="modal fade"
                id="image45"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo50} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo51}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image46"
              />
              <div
                className="modal fade"
                id="image46"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo51} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img
                src={photo52}
                className="img"
                alt=""
                data-bs-toggle="modal"
                data-bs-target="#image47"
              />
              <div
                className="modal fade"
                id="image47"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row text-end">
                        <div className="col text-end">
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="close"
                          ></button>
                        </div>
                      </div>
                      <div>
                        <img src={photo52} className="w-75 h-75" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="foot container-fluid ">
        <div className="">
          <div className="row mt-5">
            <div className="col-sm-6 mt-3 py-3 ">
              <div className="text-start mx-5 ">
                <img
                  src={logo}
                  alt=""
                  className=""
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 text-center mt-4  py-3">
              <div className="row">
                <div className="col-sm-3">
                  <Nav.Link
                    href="#cou"
                    className="me-5 fs-4"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Courses
                  </Nav.Link>
                </div>
                <div className="col-sm-3">
                  <Nav.Link
                    href="#abou"
                    className="me-5 fs-4"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    About Us
                  </Nav.Link>
                </div>
                <div className="col-sm-3">
                  <Nav.Link
                    href="#"
                    className="me-5 fs-4"
                    onClick={() => {
                      navigate("/Gallerysection");
                    }}
                  >
                    Gallery
                  </Nav.Link>
                </div>
                <div className="col-sm-3">
                  <Nav.Link
                    href="#con"
                    className="me-5 fs-4"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Contact Us
                  </Nav.Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div>
        <div className="row w-100 text-center mt-4">
          <div className="fs-6 mb-4">
            &copy;Designed And Developed By{" "}
            <a
              href="https://www.unairesync.com/"
              className="link"
              target="blank"
            >
              Unaire Sync
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nvepgallery;
