import './App.css';
import Nvep from "./nvep/Nvep";
import Gallerysection from './Gallerysection/Nvepgallery.jsx'
import { Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      {/* <Nvep/> */}
      {/* <Gallerysection/> */}

      <>
          <Routes>
            <Route path='/' element={<Nvep/>}></Route>
            <Route path='/Gallerysection' element={<Gallerysection/>}></Route>
            
          </Routes>
      </>
    </div>
  );
}

export default App;
